import Section from "../components/styles/section";
import PageHeading from "../components/page-heading";
import { useSession, signIn } from "next-auth/react";
import { useRouter } from "next/router";
import AdminLayout from "../components/layouts/admin";
import GoogleSvg from "/components/icons/google";

export default function LoginRoute(params) {
  const router = useRouter();
  const { data: session, status } = useSession();
  if (status === "loading") return "loading";
  if (session?.user) {
    router.push("/");
  }
  return (
    <div className="max-w-2xl mx-auto">
      <AdminLayout>
        <Section withContainer classes="mt-8 ">
          <PageHeading heading="Login" description="Login with Google below" />
          <button
            onClick={() => signIn("google", { callbackUrl: "/" })}
            className="flex border-2 rounded-lg border-gray-200 items-center justify-center py-3 px-4 w-full mt-4 hover:border-blue-700 hover:border-2"
          >
            <GoogleSvg />
            <span className="ml-3 font-medium">Login with Google</span>
          </button>
        </Section>
      </AdminLayout>
    </div>
  );
}
